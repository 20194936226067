<script setup>
import {
  defineProps,
  defineEmits,
  computed,
  reactive,
  ref,
  watch,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
} from "vue";
import store from "@/store";
import Fixed from "./Fixed.vue";
import Overlay from "./Overlay.vue";
import ImageKit from "@/components/ImageKit.vue";
import TokenCropper from "./TokenCropper.vue";
import { saveAs } from "file-saver";
import { gsap } from "gsap";
import Editor from "@toast-ui/editor";
import LoaderComp from "./LoaderComp.vue";
import Viewer from "@toast-ui/editor/dist/toastui-editor-viewer";
import { uniqueId } from "lodash-es";
import VueMarkdown from "vue-markdown-render";

const descriptionViewer = ref(null);
const descriptionViewerId = ref(uniqueId("md-"));

const props = defineProps({
  source: String,
});
</script>

<template>
  <div v-if="props.source" class="vue-markdown-block">
    <VueMarkdown :source="props.source" />
  </div>
</template>

<style lang="pcss">
.vue-markdown-block p {
    /* @apply mb-0 */
}
</style>
