<script setup>
import { defineProps, defineEmits } from "vue";
import cardFiles from "@/utils/cards";

const cardFile = (id) => {
  return cardFiles[id];
};

const props = defineProps({
  card: String,
  selected: Boolean,
  initial: Boolean,
  selectedText: String,
});

const emit = defineEmits(["select"]);
</script>

<template>
  <div class="mx-2 mb-6 relative" @click="emit('select', props.card)">
    <div class="card-main" :class="{ 'card-selected': props.selected, 'card-drawn': !props.initial }">
      <img :src="cardFile(card)" />
      <div class="absolute top-0 left-0 w-full h-full bg-white opacity-0 bg-opacity-70 transition duration-150 font-sans font-medium flex flex-col justify-center items-center text-sm text-ch1blue p-2 text-center" :class="{ 'opacity-100': props.selected }">
        {{ selectedText }}
      </div>
    </div>
  </div>
</template>

<style lang="pcss">
.card-main {
  transition: all ease 0.2s;
  transform: translateY(0);
  filter: drop-shadow(-3px 3px 0px rgba(0, 0, 0, 0.25));
  @apply w-24 rounded-xl overflow-hidden cursor-pointer border-2 border-transparent;
}

.card-selected {
  transform: translateY(-1rem);
  filter: drop-shadow(-8px 16px 0px rgba(0, 0, 0, 0.25));
  @apply border-2 border-red-500 bg-red-500;
}

.card-drawn {
  @apply border-green-400 bg-green-400;
}

@media (max-width: 450px) {
  .card-main {
    @apply w-20;
  }
}
</style>
