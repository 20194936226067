<script setup>
import Fixed from "../Fixed.vue";
import Overlay from "../Overlay.vue";
import Modal from "../Poker/Modal.vue";
import store from "@/store";
import { onMounted, ref } from "vue";
import { DateTime } from "luxon";
import ToggleButtons from "./ToggleButtons.vue";
import LeaderboardRow from "./LeaderboardRow.vue";
import LeaderboardRowDay from "./LeaderboardRowDay.vue";

const leaderboardData = ref(null);
const loading = ref(false);
const error = ref(null);
const getLeaderboard = async () => {
  loading.value = true;
  try {
    leaderboardData.value = await store.dispatch("poker/getLeaderboard");
  } catch (e) {
    error.value = "Could not fetch leaderboard data";
  }
  loading.value = false;
};
const hideLeaderboard = () => {
  store.dispatch("poker/setShowLeaderboard", false);
};
const nothing = () => {};

const mode = ref("Today"); // today, week, High Scores

const formatDate = (date) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
};

const changeMode = (mo) => {
  mode.value = mo;
};
onMounted(() => {
  getLeaderboard();
});

const selectedToday = ref(null);

const selectRecordToday = (rank) => {
  if (selectedToday.value === rank) {
    selectedToday.value = null;
  } else {
    selectedToday.value = rank;
  }
};
</script>

<template>
  <Fixed>
    <Overlay @click="hideLeaderboard">
      <Modal @click.stop="nothing">
        <div class="leaderboard-modal max-w-full">
          <div
            class="w-full flex flex-col lg:flex-row justify-between, items-start lg:items-center"
          >
            <div class="flex mb-4 lg:mb-0 mt-4">
              <div class="font-ch1script text-6xl text-ch1blue">Leaderboard</div>
            </div>
            <div class="flex flex-grow md:justify-end flex-wrap md:flex-nowrap">
              <ToggleButtons
                v-model="mode"
                :modes="['Today', 'Week', 'Last Week', 'High Scores']"
              />
            </div>
          </div>
          <div class="my-6 text-left overflow-auto w-full">
            <template v-if="loading">
              <div>Loading...</div>
            </template>
            <template v-else-if="error"> </template>
            <template v-else-if="leaderboardData">
              <div class="w-full" v-if="mode === 'Today'">
                <div class="font-display mb-4 text-ch1blue text-xl">
                  Date: {{ formatDate(leaderboardData.today_date) }}
                </div>
                <div
                  class="py-6 text-center"
                  v-if="!leaderboardData.today?.filter((p) => p.winnings_total !== null)?.length"
                >
                  No players yet today
                </div>
                <div class="flex flex-col w-full" v-else>
                  <LeaderboardRow
                    v-for="(player, p) in leaderboardData.today"
                    :key="p"
                    :record="player"
                    :rank="p + 1"
                    :dates="[leaderboardData.today_date]"
                    @selected="selectRecordToday"
                    :opened="p + 1 === selectedToday"
                  />
                </div>
              </div>
              <div class="w-full" v-if="mode === 'Week'">
                <div class="font-display mb-4 text-ch1blue text-xl">
                  Dates: {{ formatDate(leaderboardData.week_start_date) }} to
                  {{ formatDate(leaderboardData.week_end_date) }}
                </div>
                <div
                  class="py-6 text-center"
                  v-if="!leaderboardData.week?.filter((p) => p.winnings_total !== null)?.length"
                >
                  No players yet this week
                </div>
                <div class="flex flex-col w-full" v-else>
                  <LeaderboardRow
                    v-for="(player, p) in leaderboardData.week"
                    :key="p"
                    :record="player"
                    :rank="p + 1"
                  />
                </div>
              </div>
              <div class="w-full" v-if="mode === 'Last Week'">
                <div class="font-display mb-4 text-ch1blue text-xl">
                  Dates: {{ formatDate(leaderboardData.last_week_start_date) }} to
                  {{ formatDate(leaderboardData.last_week_end_date) }}
                </div>
                <div
                  class="py-6 text-center"
                  v-if="
                    !leaderboardData.last_week?.filter((p) => p.winnings_total !== null)?.length
                  "
                >
                  No players last week
                </div>
                <div class="flex flex-col w-full" v-else>
                  <LeaderboardRow
                    v-for="(player, p) in leaderboardData.last_week"
                    :key="p"
                    :record="player"
                    :rank="p + 1"
                  />
                </div>
              </div>
              <div class="w-full" v-if="mode === 'High Scores'">
                <div class="flex flex-col w-full">
                  <div class="font-display mb-4 text-ch1blue text-xl">
                    Top 20 Scores (last 30 days)
                  </div>
                  <LeaderboardRowDay
                    v-for="(player, p) in leaderboardData.top_scores"
                    :key="p"
                    :record="player"
                    :rank="p + 1"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
      </Modal>
    </Overlay>
  </Fixed>
</template>

<style lang="postcss">
@media (min-width: 1100px) {
  .leaderboard-modal {
    width: 1000px;
  }
}
</style>
