<script setup>
import { onMounted, computed, ref, reactive } from "vue";
import store from "@/store";
import Game from "./Game.vue";
import TokenCard from "./TokenCard.vue";
import Overlay from "../Overlay.vue";
import Modal from "../Poker/Modal.vue";
import Fixed from "../Fixed.vue";
import MarkdownBlock from "../MarkdownBlock.vue";
import VueMarkdown from "vue-markdown-render";

const checkingTokens = ref(false);
// const day = ref(null);
const day = computed(() => {
  return store.state.poker.day;
});

onMounted(async () => {
  if (!hasLocked.value) {
    fetchTokens();
  }
});

const tokens = reactive({
  list: [],
  length: 0,
});

const fetchTokens = async () => {
  checkingTokens.value = true;
  let dataTokens = await store.dispatch("poker/tokens");
  // tokens.list = dataTokens?.sort(function (x, y) {
  //   // true values first
  //   return x?.used_recently === y?.used_recently ? 0 : x ? -1 : 1;
  //   // false values first
  //   // return (x === y)? 0 : x? 1 : -1;
  // });
  tokens.list = dataTokens.sort((a, b) => b.used_recently - a.used_recently);
  checkingTokens.value = false;
};

const hasLocked = computed(() => {
  return !!day.value?.started_at;
});

const selectedTokens = ref([]);

const selectToken = (token) => {
  if (!day.value?.started) {
    if (selectedTokens.value.find((tok) => tok == token)) {
      selectedTokens.value = [...selectedTokens.value.filter((tok) => tok != token)];
    } else {
      if (selectedTokens.value?.length < day.value?.max_tokens) {
        selectedTokens.value = [...selectedTokens.value, token];
      }
    }
  }
};

const tokenIsSelected = (token) => {
  return selectedTokens.value?.find((tok) => tok == token);
};

const tokenIsSelectedBool = (token) => {
  return !!tokenIsSelected(token);
};

const showConfirmModal = ref(false);

const lockingTeamChecking = ref(null);
const maxSelectableTokens = computed(() => {
  return day.value?.max_tokens ?? 0;
  // if (!tokens.list.length) {
  //   return 0;
  // }

  // return Math.min(tokens.list.length, 3);
});

const chooseTeam = async (confirmed = false) => {
  if (selectedTokens.value?.length < maxSelectableTokens.value && confirmed !== "yes") {
    showConfirmModal.value = true;
    return;
  }
  lockingTeamChecking.value = "Entering the saloon";
  let tokenIds = selectedTokens.value.map((tok) => tok.keyId);
  let successfulLock = await store.dispatch("poker/lockDay", tokenIds);
  if (!successfulLock) {
    await fetchTokens();
  }
  lockingTeamChecking.value = null;
  selectedTokens.value = [];
};

const tokenListSorted = computed(() => {
  return tokens?.list ?? [];
});

const refreshTokenBalances = async () => {
  checkingTokens.value = true;
  await store.dispatch("users/refreshTokenBalances");
  await fetchTokens();
  checkingTokens.value = false;
};

const convertNumbersToWords = (num) => {
  switch (num) {
    case 0:
      return "zero";
    case 1:
      return "one";
    case 2:
      return "two";
    case 3:
      return "three";
    case 4:
      return "four";
    case 5:
      return "five";
    case 6:
      return "six";
    case 7:
      return "seven";
    case 8:
      return "eight";
    case 9:
      return "nine";
    case 10:
      return "ten";
    case 11:
      return "eleven";
  }
};

const choseNext = ref(false);
</script>

<template>
  <template v-if="!checkingTokens">
    <template v-if="!hasLocked">
      <!-- <Overlay> -->
      <div class="" v-if="day?.scenario">
        <div class="mb-8 font-serif" v-if="!choseNext">
          <div
            class="bg-ch1cream p-8 mb-4 rounded-xl box-shadow-std flex flex-col md:flex-row items-start"
          >
            <div class="rounded-lg overflow-hidden w-full md:w-1/2 mb-6 md:mb-0">
              <img :src="require('@/assets/backgrounds/saloon-v1-min.jpg')" />
            </div>

            <div class="w-full md:w-1/2 pl-0 md:pl-8">
              <div
                v-if="day?.scenario.title"
                class="font-ch1script text-5xl mb-8 text-center md:text-left"
              >
                {{ day.scenario.title }}
              </div>
              <div v-if="day?.scenario?.preamble_text" class="mb-6">
                <MarkdownBlock :source="day.scenario.preamble_text" />
              </div>

              <!-- <div v-if="day?.scenario?.selection_text" class="mb-6">
                <MarkdownBlock :source="day.scenario.selection_text" />
              </div> -->

              <div class="flex justify-center md:justify-start">
                <button @click="() => (choseNext = true)" class="btn btn-ch1blue text-white">
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="bg-ch1cream p-8 mb-8 rounded-xl box-shadow-std flex flex-col md:flex-row items-start"
          >
            <div class="rounded-lg overflow-hidden w-full md:w-1/2 mb-6 md:mb-0">
              <img :src="require('@/assets/backgrounds/saloon-inside-v1-min.jpg')" />
            </div>

            <div class="w-full md:w-1/2 pl-0 md:pl-8">
              <div v-if="day?.scenario?.selection_text" class="font-serif">
                <MarkdownBlock :source="day.scenario.selection_text" />
              </div>
            </div>
          </div>

          <div class="p-8 rounded-xl bg-white bg-opacity-20">
            <div class="flex flex-col md:flex-row items-start md:items-center justify-between mb-8">
              <div class="text-white mb-4 md:mb-0">
                <div class="font-ch1script text-4xl mb-2">Choose your team</div>
                <div class="font-serif text-xl">
                  Select up to
                  <span class="text-ch1gold">{{ convertNumbersToWords(day.max_tokens) }}</span>
                  character{{ day?.max_tokens === 1 ? "" : "s" }} for your team for today!
                </div>
              </div>
              <div class="">
                <button @click="chooseTeam(false)" class="btn btn-ch1lightblue text-white">
                  Begin
                </button>
              </div>
            </div>
            <div class="flex items-center justify-center flex-wrap">
              <TokenCard
                v-for="(token, t) in tokenListSorted"
                :key="t"
                :selected="tokenIsSelectedBool(token)"
                :token="token"
                @select="selectToken"
              />
            </div>
          </div>
        </div>
      </div>
      <Fixed v-if="showConfirmModal">
        <Overlay
          @click="
            () => {
              showConfirmModal = false;
            }
          "
        >
          <Modal>
            <div class="text-center mb-8 font-serif">
              You haven't selected
              {{ !selectedTokens.length ? "any" : maxSelectableTokens }} tokens. <br />Are you sure
              you want start?
            </div>
            <div class="flex justify-between items-center">
              <button
                @click="
                  () => {
                    showConfirmModal = false;
                  }
                "
                class="btn btn-ch1red text-white mr-8"
              >
                Cancel
              </button>
              <button @click="chooseTeam('yes')" class="btn btn-ch1lightblue text-white">
                Start Game
              </button>
            </div>
          </Modal>
        </Overlay>
      </Fixed>
    </template>
    <template v-else>
      <Game />
    </template>
  </template>
  <template v-else>
    <!-- <Overlay> -->
    <div class="bg-ch1cream rounded-xl box-shadow-std p-8 text-center my-12">
      <div class="">Loading...</div>
    </div>
    <!-- </Overlay> -->
  </template>
  <template v-if="lockingTeamChecking">
    <Fixed>
      <Overlay>
        <Modal>{{ lockingTeamChecking }}... </Modal>
      </Overlay>
    </Fixed>
  </template>
</template>

<style lang="pcss">
.empty-card {
  width: 100%;
  @apply bg-ch1cream bg-opacity-75 flex-col items-start p-4 rounded-md border-8 border-transparent mx-4 mb-4;
  box-shadow: -6px 6px 0px rgba(0, 0, 0, 0.25);
}
</style>
