<script setup>
import { defineProps, defineEmits } from "vue";
import cardFiles from "@/utils/cards";

const cardFile = (id) => {
  return cardFiles[id];
};

const props = defineProps({
  card: String,
  initial: Boolean,
});

// const emit = defineEmits(["select"]);
</script>

<template>
  <div class="mx-2 mb-2">
    <img :src="cardFile(card)" class="border-red-500 card-main-small" :class="{ 'card-drawn': !props.initial }" />
  </div>
</template>

<style lang="pcss">
.card-main-small {
  transition: all ease 0.2s;
  transform: translateY(0);
  filter: drop-shadow(-3px 3px 0px rgba(0, 0, 0, 0.25));
  @apply w-16 cursor-pointer border-2 border-transparent rounded-lg;
}

.card-drawn {
  @apply border-green-400 bg-green-400;
}

@media (max-width: 450px) {
  .card-main-small {
    @apply w-12;
  }
}
</style>
