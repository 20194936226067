<script setup>
import { onMounted, computed, ref, watch, onUnmounted } from "vue";
import store from "@/store";
import cardFiles from "@/utils/cards";
import Fixed from "../Fixed.vue";
import Modal from "../Poker/Modal.vue";
import Overlay from "../Overlay.vue";
import TokenCard from "./TokenCard.vue";
import Card from "./Card.vue";
import DrawButton from "./DrawButton.vue";
import { DateTime } from "luxon";
import router from "@/router";

const loading = ref(null);

// const day = ref(null);
// const day = computed(() => {
//   return store.state.poker.day;
// });
const tempGameCounts = computed(() => {
  return store.state.poker.tempGameCounts;
});

const user = computed(() => {
  return store.state.auth.user;
});

const game = computed(() => {
  return store.state.poker.tempGame;
});

onMounted(async () => {
  fetchTempGame();
});

const fetchTempGame = async () => {
  const req = await store.dispatch("poker/getTempGame");
  //   game.value = req.current_game;
};

// const game = ref(null);
const startTempGame = async () => {
  loading.value = "Starting a new game";
  await store.dispatch("poker/startTempGame");
  //   await store.dispatch("poker/getDay");
  //   game.value = gm;
  loading.value = null;
};

const selectedCards = ref([]);

const selectCard = (id) => {
  if (!game.value?.completed_at) {
    if (selectedCards.value.indexOf(id) > -1) {
      selectedCards.value = [...selectedCards.value.filter((c) => c != id)];
    } else {
      selectedCards.value = [...selectedCards.value, id];
    }
  }
};

const isSelected = (card) => {
  return selectedCards.value.indexOf(card) > -1;
};

const tradeTempCards = async () => {
  loading.value = "Drawing new cards";
  await store.dispatch("poker/drawTempGame", selectedCards.value);
  //   await store.dispatch("poker/getDay");
  //   game.value = gm;
  selectedCards.value = [];
  loading.value = null;
};

const errorMessage = ref(null);
const clearErrorMessage = () => {
  errorMessage.value = null;
};
// const powerupMessage = ref(null);
// const usePowerup = async (powerup) => {
//   loading.value = "Helping your game, applying the powerup";
//   if (game.value.completed_at) {
//     loading.value = null;
//     return;
//   }
//   let pw = await store.dispatch("poker/powerup", { cards: selectedCards.value, powerup: powerup.keyId });
//   selectedCards.value = [];
//   loading.value = null;
//   if (pw?.powerup?.message) {
//     powerupMessage.value = pw.powerup.message;
//   }
// };

// const clearPowerupMessage = () => {
//   powerupMessage.value = null;
// };

// const canPlayPowerup = computed(() => {
//   return !game.value.completed_at;
// });

const countdownDisplay = computed(() => {
  return store.state.poker.gameResetDisplay;
});

const showMemory = () => {
  // store.dispatch("poker/setShowMemory", true);
  router.push({ name: "Memory" });
};

// const hasPowerups = computed(() => {
//   return day?.value?.powerup_data?.filter((p) => p.powerup_uses > 0)?.length;
// });

const selectedText = computed(() => {
  return "Selected for discard";
});

const disallowed = computed(() => {
  return false;
  //   return !user.value?.has_soth_nfts;
});
</script>

<template>
  <div class="flex flex-col items-center justify-center pb-12">
    <!-- <div class="mt-8 mb-6">
      <img :src="require('@/assets/chapter-1/soth-clear-logo.png')" class="md:block w-52 md:h-20" />
    </div> -->
    <!-- <div class="p-2 bg-yellow-300 text-sm mb-4 mx-4 text-center" v-if="!user.has_soth_nfts">Society of the Hourglass is minting now!</div> -->
    <template v-if="game">
      <div class="text-center font-display text-white text-xl mb-6" v-if="game.completed_at">
        Hand complete!
      </div>
      <div class="text-center font-display text-white text-xl mb-6" v-else>
        Pick the card(s)<br />
        you want to swap, then <span class="text-ch1gold italic">draw!</span>
      </div>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="gameboard container mb-12">
          <div class="flex flex-col items-center mb-8">
            <div class="flex flex-wrap justify-center">
              <template v-for="card in game.hand" :key="card">
                <Card
                  :card="card"
                  @select="selectCard(card)"
                  :selected="isSelected(card)"
                  :selected-text="selectedText"
                  :initial="!!game.initial_hand?.find((c) => c == card)"
                />
              </template>
            </div>
          </div>

          <div class="text-ch1gold font-display text-3xl mb-12 text-center">
            <div>
              {{ game.result_description }}
            </div>
            <div class="text-lg" v-if="game.completed_at">
              <span class="text-white">You won</span> {{ game.winnings_final }}
              <span class="text-white"
                >chip{{ game.winnings_final == 1 ? "" : "s"
                }}{{ game.winnings_final > 0 ? "!" : "." }}</span
              >
            </div>
          </div>

          <div class="flex justify-center">
            <template v-if="disallowed">
              <div class="text-center max-w-sm flex flex-col items-center">
                <div class="text-white text-center mb-4 font-display text-lg">
                  Uh oh - looks like you don't have any Society of the Hourglass Main Collection
                  tokens! You can buy some to play!
                </div>
                <div class="flex justify-center md:justify-start">
                  <a
                    href="https://opensea.io/collection/society-of-the-hourglass"
                    target="_blank"
                    class="btn bg-ch1gold text-white"
                    >Buy on OpenSea</a
                  >
                </div>
              </div>
            </template>
            <template v-else-if="game && !game.completed_at">
              <DrawButton @select="tradeTempCards" />
            </template>
            <template v-else-if="tempGameCounts.remaining > 0">
              <button class="btn btn-ch1lightblue text-white" @click="startTempGame">
                Start New Hand
              </button>
            </template>
            <template v-else>
              <div class="flex flex-col items-center">
                <div class="text-white">No more hands - come back tomorrow!</div>
                <div class="mt-4 font-display text-white text-xl text-center">
                  Game resets in {{ countdownDisplay }}
                </div>
                <div
                  class="text-center mt-8 w-full max-w-lg bg-white bg-opacity-80 p-4"
                  v-if="user.has_soth_nfts"
                >
                  <router-link :to="{ name: 'Poker' }" class="text-black"
                    >Try our full-featured game mode with unique powerups per token, leaderboard
                    rankings, and earnings for use in later Society of the Hourglass
                    games!</router-link
                  >
                  <router-link :to="{ name: 'Poker' }" class="btn bg-ch1blue-light mt-4 text-white"
                    >Play Powerup Poker</router-link
                  >
                </div>
                <div class="text-center mt-8 w-full max-w-lg bg-white bg-opacity-80 p-4" v-else>
                  <router-link :to="{ name: 'Poker' }" class="text-black"
                    >Try our full-featured game mode with unique powerups per token, leaderboard
                    rankings, and earnings for use in later Society of the Hourglass
                    games!</router-link
                  >
                  <div class="italic text-sm my-4">
                    Requires ownership of at least one Society of the Hourlass token.
                  </div>
                  <a
                    href="https://opensea.io/collection/society-of-the-hourglass"
                    target="_blank"
                    class="btn bg-ch1gold text-white"
                    >Buy on OpenSea</a
                  >
                </div>
              </div>
            </template>
          </div>
        </div>

        <!-- <div class="mb-8 container">
          <div class="flex flex-wrap justify-center">
            <template v-for="(pow, p) in day.powerup_data" :key="p">
              <template v-if="pow.powerup_uses > 0">
                <TokenCard :token="pow" @select="usePowerup" :btnLabel="`Use Perk`" usesLabel="Remaining" />
              </template>
            </template>
          </div>
        </div> -->
      </div>
    </template>
    <template v-else>
      <div class="container">
        <div class="py-24 flex justify-center">
          <div v-if="!disallowed">
            <button class="btn btn-ch1lightblue text-white" @click="startTempGame">
              Start Game
            </button>
          </div>
          <div v-else>
            <div class="text-center max-w-sm flex flex-col items-center">
              <div class="text-white text-center mb-4 font-display text-lg">
                Uh oh - looks like you don't have any Society of the Hourglass Main Collection
                tokens! You can buy some to play!
              </div>
              <div class="flex justify-center md:justify-start">
                <a
                  href="https://opensea.io/collection/society-of-the-hourglass"
                  target="_blank"
                  class="btn bg-ch1gold text-white"
                  >Buy on OpenSea</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>

  <!-- <Fixed v-if="powerupMessage">
    <Overlay @click="clearPowerupMessage">
      <Modal> {{ powerupMessage }} </Modal>
    </Overlay>
  </Fixed> -->

  <Fixed v-if="loading">
    <Overlay>
      <Modal> {{ loading }}... </Modal>
    </Overlay>
  </Fixed>
</template>
