<script setup>
import Fixed from "../Fixed.vue";
import Overlay from "../Overlay.vue";
import Modal from "../Poker/Modal.vue";
import store from "@/store";
import { computed, onMounted, ref } from "vue";
import { DateTime } from "luxon";
import ToggleButtons from "./ToggleButtons.vue";

const leaderboardData = ref(null);
const loading = ref(false);
const error = ref(null);

const hideStats = () => {
  store.dispatch("poker/setShowStats", false);
};
const nothing = () => {
  //  shouldn't hide
};

const mode = ref("Gameplay"); // today, week, overall
const modes = [
  "Gameplay",
  "Powerups",
  "Earning",
  // "More Info"
];

const formatDate = (date) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
};

const user = computed(() => {
  return store.state.auth.user;
});
</script>

<template>
  <Fixed>
    <Overlay @click="hideStats">
      <Modal @click.stop="nothing">
        <div class="stats-modal max-w-full">
          <div
            class="w-full flex flex-col lg:flex-row justify-between, items-start lg:items-center mb-8"
          >
            <div class="flex mb-4 lg:mb-0 mt-4">
              <div class="font-ch1script text-6xl text-ch1blue">Your Stats</div>
            </div>
            <div class="flex flex-grow md:justify-end flex-wrap md:flex-nowrap"></div>
          </div>
          <div class="my-6 text-left overflow-auto w-full">
            <div class="w-full">
              <!-- <div class="text-lg font-display mb-0 text-gray-400">Total Bets</div>
              <div class="text-xl font-display mb-4">{{ user.chips.bets }} chips</div> -->
              <div class="text-lg font-display mb-0 text-gray-400">Total Winnings</div>
              <div class="text-xl font-display mb-4">{{ user.chips.winnings }} chips</div>
              <div class="text-lg font-display mb-0 text-gray-400">Daily Bonuses</div>
              <div class="text-xl font-display mb-4">{{ user.chips.bonuses }} chips</div>
              <div class="text-lg font-display mb-0 text-gray-400">Adjustments</div>
              <div class="text-xl font-display mb-4">{{ user.chips.adjustments }} chips</div>
              <div class="text-lg font-display mb-0 text-gray-400">Total</div>
              <div class="text-xl font-display mb-4">{{ user.chips.total }} chips</div>
              <template v-if="user.chips?.best_poker_day">
                <div
                  class="text-lg font-display mb-0 text-gray-400 mt-4 pt-4 border-t border-gray-200"
                >
                  Best Day
                </div>
                <div class="text-xl font-display mb-4">
                  {{ formatDate(user.chips?.best_poker_day.date) }}:
                  {{ user.chips?.best_poker_day.winnings_final }} chips
                </div>
              </template>
            </div>
          </div>
        </div>
      </Modal>
    </Overlay>
  </Fixed>
</template>

<style lang="postcss">
@media (min-width: 1100px) {
  .stats-modal {
    width: 500px;
  }
}
</style>
