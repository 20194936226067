<script setup>
import Navigation from "@/components/Poker/Navigation";
import Login from "@/components/Poker/Login.vue";
import store from "@/store";
import { computed, ref, onMounted, onUnmounted, defineProps } from "vue";
import Leaderboard from "@/components/Poker/Leaderboard.vue";
import { DateTime } from "luxon";
import Rules from "@/components/Poker/Rules.vue";
import Stats from "@/components/Poker/Stats.vue";
import Memory from "@/components/Poker/Memory.vue";
import router from "@/router";
import LoadUser from "@/components/LoadUser.vue";
import NavigationNew from "@/components/NavigationNew.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

const props = defineProps({
  freeplay: Boolean,
});

const showLeaderboard = computed(() => {
  return store.state.poker.showLeaderboard;
});

const showRules = computed(() => {
  return store.state.poker.showRules;
});

const showStats = computed(() => {
  return store.state.poker.showStats;
});

const showMemory = computed(() => {
  return store.state.poker.showMemory;
});

const countDownTimer = ref(null);
const setCountDownDisplay = () => {
  const end = DateTime.now({}).setZone("UTC").plus({ days: 1 }).startOf("day");
  const start = DateTime.now().setZone("UTC");
  const diff = end.diff(start, ["hours", "minutes"]).toObject();
  let valueDisplay;
  if (diff.hours > 0) {
    valueDisplay = `${diff.hours} hour${diff.hours === 1 ? "" : "s"} and ${Math.round(
      diff.minutes
    )} minute${Math.round(diff.minutes) === 1 ? "" : "s"}`;
  } else {
    valueDisplay = `${Math.round(diff.minutes)} minute${Math.round(diff.minutes) === 1 ? "" : "s"}`;
  }

  store.dispatch("poker/setGameResetDisplay", valueDisplay);

  if (diff.seconds <= 60) {
    setTimeout(() => {
      location.reload();
    }, diff.seconds * 1000);
  }
};

onMounted(() => {
  setCountDownDisplay();
  countDownTimer.value = setInterval(() => {
    setCountDownDisplay();
  }, 60000);
});

onUnmounted(() => {
  clearInterval(countDownTimer.value);
});

const version = process.env.VUE_APP_PACKAGE_VERSION;

const goToFreeplay = () => {
  router.push({ name: "PokerStandard" });
};

const setShowLeaderboard = () => {
  store.dispatch("poker/setShowLeaderboard", true);
};

const setShowRules = () => {
  store.dispatch("poker/setShowRules", true);
};
</script>

<template>
  <div
    class="h-full bg-cover flex items-center justify-center bg-center relative flex-col"
    :style="{
      'background-image': `url('${require('@/assets/backgrounds/poker-v1-square.jpg')}')`,
    }"
  >
    <NavigationNew mode="light" />

    <Breadcrumbs
      :links="[
        { routeName: 'Home', label: 'Home' },
        { routeName: 'Arcade', label: 'Arcade' },
        { label: props.freeplay ? 'Standard Draw Poker' : 'Powerup Poker' },
      ]"
    />

    <LoadUser :redirect="false">
      <div class="container">
        <div class="chapter-one w-full h-full flex flex-col">
          <!-- <NavigationNew mode="top" /> -->
          <Navigation :freeplay="props.freeplay" />
          <div class="relative top-0 left-0 w-full">
            <div class="container flex justify-end pt-6">
              <div
                class="w-9 h-9 bg-white rounded-full flex items-center justify-center cursor-pointer box-shadow-main"
                @click="setShowLeaderboard"
              >
                <div class="w-8 h-8 bg-ch1blue rounded-full flex items-center justify-center">
                  <div
                    class="w-6 h-6 bg-white rounded-full flex items-center justify-center font-brother text-center"
                  >
                    <img :src="require('@/assets/graph-blue.svg')" class="h-3/4" />
                  </div>
                </div>
              </div>
              <div
                class="ml-4 w-9 h-9 bg-white rounded-full flex items-center justify-center cursor-pointer box-shadow-main"
                @click="setShowRules"
              >
                <div class="w-8 h-8 bg-ch1blue rounded-full flex items-center justify-center">
                  <div
                    class="w-6 h-6 bg-white rounded-full flex items-center justify-center font-brother text-center"
                  >
                    <img :src="require('@/assets/question-mark-blue.svg')" class="h-3/4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-grow w-full relative py-8">
            <div
              class="h-full flex flex-col items-center"
              :style="{
                // 'background-image': `url('${require('@/assets/backgrounds/poker-v1-square.jpg')}')`,
                // 'background-position': 'top center',
              }"
            >
              <div class="flex-grow w-full">
                <Login :freeplay="props.freeplay" />
              </div>
              <div
                class="relative w-full text-center bottom-0 left-0 font-display text-white italic py-4 z-10"
              >
                &copy; Society of the Hourglass 2022 | v{{ version }}
              </div>
            </div>
          </div>
          <Leaderboard v-if="showLeaderboard" />
          <Rules v-if="showRules" />
          <Stats v-if="showStats" />
          <Memory v-if="showMemory" />
        </div>
      </div>
    </LoadUser>
  </div>
</template>

<style lang="pcss">
/* .poker-bg {
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #fbeac3;
}
@media (max-width: 1550px) {
  .poker-bg {
    background-size: 1600px 864px;
    background-repeat: no-repeat;
    background-color: #fbeac3;
  }
} */
</style>
