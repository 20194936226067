<script setup>
import { onMounted, computed, ref, defineProps } from "vue";
import store from "@/store";
import router from "@/router";
import Day from "./Day.vue";
import Overlay from "../Overlay.vue";
import Modal from "../Poker/Modal.vue";
import GameTemp from "./GameTemp.vue";
import Fixed from "../Fixed.vue";

const props = defineProps({
  freeplay: Boolean,
});

const loading = ref(false);
const isLoggedIn = computed(() => {
  return store.getters["auth/isLoggedIn"];
});
const checkingLogin = ref(true);

const login = async () => {
  router.push({
    name: "Login",
    query: {
      from: props.freeplay ? encodeURI("/arcade/poker/standard") : encodeURI("/arcade/poker"),
    },
  });
  // loading.value = true;
  // await store.dispatch("auth/login");
  // loading.value = false;
};

onMounted(async () => {
  // check if you are logged in
  checkingLogin.value = true;
  await store.dispatch("auth/getUser");
  checkingLogin.value = false;
  // check if you have logged in for the day
});
</script>

<template>
  <template v-if="!checkingLogin">
    <template v-if="!isLoggedIn">
      <!-- <Overlay> -->
      <div class="bg-ch1cream rounded-xl p-8 box-shadow-std">
        <div class="flex flex-col items-center p-8">
          <div class="mb-4 text-ch1blue font-black">You must be logged in to play.</div>
          <div>
            <button @click="login" class="btn bg-ch1blue text-white">Login</button>
          </div>
        </div>
      </div>
      <!-- </Overlay> -->
    </template>
    <template v-else>
      <Day v-if="!props.freeplay" />
      <GameTemp v-else />
    </template>
  </template>
  <template v-else>
    <Fixed>
      <Overlay>
        <Modal>Checking login status...</Modal>
      </Overlay>
    </Fixed>
  </template>
</template>
