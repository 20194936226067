<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { DateTime } from "luxon";
import CardSmall from "./CardSmall.vue";
import TokenCardSmall from "./TokenCardSmall.vue";

const props = defineProps({
  rank: Number,
  record: Object,
  dates: Array,
  opened: Boolean,
});

// {
//   username:
//   winnings_total
//   poker_days: [ { date:  } ]
// }

const emit = defineEmits(["selected"]);

const days = computed(() => {
  if (!props.dates?.length) {
    return props.record?.poker_days ?? [];
  }

  return props.dates?.map((date) => {
    return props.record?.poker_days?.find((day) => {
      return day.date === date;
    });
  });
});

const formatDate = (date) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
};

const abbrevAddress = (address) => {
  return `${address?.substring(0, 5)}...${address?.substring(address?.length - 4)}`;
};
</script>

<template>
  <div class="flex flex-col flex-grow p-4 bg-white border border-ch1blue-light rounded mb-4">
    <div
      class="flex items-start flex-col md:flex-row md:flex-grow md:items-center cursor-pointer overflow-hidden"
      @click="emit('selected', props.rank)"
    >
      <div
        class="flex flex-col justify-center items-center rounded-full p-2 text-xs bg-ch1blue text-white w-10 h-10 mr-4"
      >
        #{{ props.rank }}
      </div>
      <div class="text-lg font-medium mr-6 truncate max-w-full">
        {{ props.record?.user?.username }}
      </div>
      <div class="italic">
        {{ props.record.winnings_final }} chips on {{ formatDate(props.record.date) }}
      </div>
    </div>
  </div>
</template>
