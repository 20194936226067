const cards = {
  H01: require("@/assets/cards/id/h01-min.png"),
  H02: require("@/assets/cards/id/h02-min.png"),
  H03: require("@/assets/cards/id/h03-min.png"),
  H04: require("@/assets/cards/id/h04-min.png"),
  H05: require("@/assets/cards/id/h05-min.png"),
  H06: require("@/assets/cards/id/h06-min.png"),
  H07: require("@/assets/cards/id/h07-min.png"),
  H08: require("@/assets/cards/id/h08-min.png"),
  H09: require("@/assets/cards/id/h09-min.png"),
  H10: require("@/assets/cards/id/h10-min.png"),
  H11: require("@/assets/cards/id/h11-min.png"),
  H12: require("@/assets/cards/id/h12-min.png"),
  H13: require("@/assets/cards/id/h13-min.png"),
  D01: require("@/assets/cards/id/d01-min.png"),
  D02: require("@/assets/cards/id/d02-min.png"),
  D03: require("@/assets/cards/id/d03-min.png"),
  D04: require("@/assets/cards/id/d04-min.png"),
  D05: require("@/assets/cards/id/d05-min.png"),
  D06: require("@/assets/cards/id/d06-min.png"),
  D07: require("@/assets/cards/id/d07-min.png"),
  D08: require("@/assets/cards/id/d08-min.png"),
  D09: require("@/assets/cards/id/d09-min.png"),
  D10: require("@/assets/cards/id/d10-min.png"),
  D11: require("@/assets/cards/id/d11-min.png"),
  D12: require("@/assets/cards/id/d12-min.png"),
  D13: require("@/assets/cards/id/d13-min.png"),
  C01: require("@/assets/cards/id/c01-min.png"),
  C02: require("@/assets/cards/id/c02-min.png"),
  C03: require("@/assets/cards/id/c03-min.png"),
  C04: require("@/assets/cards/id/c04-min.png"),
  C05: require("@/assets/cards/id/c05-min.png"),
  C06: require("@/assets/cards/id/c06-min.png"),
  C07: require("@/assets/cards/id/c07-min.png"),
  C08: require("@/assets/cards/id/c08-min.png"),
  C09: require("@/assets/cards/id/c09-min.png"),
  C10: require("@/assets/cards/id/c10-min.png"),
  C11: require("@/assets/cards/id/c11-min.png"),
  C12: require("@/assets/cards/id/c12-min.png"),
  C13: require("@/assets/cards/id/c13-min.png"),
  S01: require("@/assets/cards/id/s01-min.png"),
  S02: require("@/assets/cards/id/s02-min.png"),
  S03: require("@/assets/cards/id/s03-min.png"),
  S04: require("@/assets/cards/id/s04-min.png"),
  S05: require("@/assets/cards/id/s05-min.png"),
  S06: require("@/assets/cards/id/s06-min.png"),
  S07: require("@/assets/cards/id/s07-min.png"),
  S08: require("@/assets/cards/id/s08-min.png"),
  S09: require("@/assets/cards/id/s09-min.png"),
  S10: require("@/assets/cards/id/s10-min.png"),
  S11: require("@/assets/cards/id/s11-min.png"),
  S12: require("@/assets/cards/id/s12-min.png"),
  S13: require("@/assets/cards/id/s13-min.png"),
  J01: require("@/assets/cards/id/j01-min.png"),
  J02: require("@/assets/cards/id/j02-min.png"),
  BACK: require("@/assets/cards/id/back-min.png"),
};

export default cards;
