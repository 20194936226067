<script setup>
import { onMounted, computed, ref, watch, onUnmounted } from "vue";
import store from "@/store";
import cardFiles from "@/utils/cards";
import Fixed from "../Fixed.vue";
import Modal from "../Poker/Modal.vue";
import Overlay from "../Overlay.vue";
import TokenCard from "./TokenCard.vue";
import Card from "./Card.vue";
import DrawButton from "./DrawButton.vue";
import { DateTime } from "luxon";
import router from "@/router";

const loading = ref(null);

// const day = ref(null);
const day = computed(() => {
  return store.state.poker.day;
});

const game = computed(() => {
  return store.state.poker.game;
});

onMounted(async () => {
  fetchDay();
});

const fetchDay = async () => {
  const req = await store.dispatch("poker/getDay");
  //   game.value = req.current_game;
};

// const game = ref(null);
const startGame = async () => {
  clearPowerupMessage();
  loading.value = "Starting a new game";
  await store.dispatch("poker/startGame");
  //   await store.dispatch("poker/getDay");
  //   game.value = gm;
  loading.value = null;
};

const selectedCards = ref([]);

const selectCard = (id) => {
  if (!game.value?.completed_at) {
    if (selectedCards.value.indexOf(id) > -1) {
      selectedCards.value = [...selectedCards.value.filter((c) => c != id)];
    } else {
      selectedCards.value = [...selectedCards.value, id];
    }
  }
};

const isSelected = (card) => {
  return selectedCards.value.indexOf(card) > -1;
};

const cardFile = (id) => {
  return cardFiles[id];
};

const tradeCards = async () => {
  clearPowerupMessage();
  loading.value = "Drawing new cards";
  await store.dispatch("poker/draw", selectedCards.value);
  //   await store.dispatch("poker/getDay");
  //   game.value = gm;
  selectedCards.value = [];
  loading.value = null;
};

const powerupMessage = ref(null);

const usePowerup = async (powerup) => {
  clearPowerupMessage();
  loading.value = "Helping your game, applying the powerup";
  if (game.value.completed_at) {
    loading.value = null;
    return;
  }
  let pw = await store.dispatch("poker/powerup", {
    cards: selectedCards.value,
    powerup: powerup.keyId,
  });

  selectedCards.value = [];
  loading.value = null;
  if (pw?.powerup?.message) {
    powerupMessage.value = pw.powerup.message;
  }
};

const clearPowerupMessage = () => {
  powerupMessage.value = null;
};

const canPlayPowerup = computed(() => {
  return !game.value.completed_at;
});

const countdownDisplay = computed(() => {
  return store.state.poker.gameResetDisplay;
});

const showMemory = () => {
  // store.dispatch("poker/setShowMemory", true);
  router.push({ name: "Memory" });
};

const hasPowerups = computed(() => {
  return day?.value?.powerup_data?.filter((p) => p.powerup_uses > 0)?.length;
});

const selectedText = computed(() => {
  return hasPowerups.value ? "Selected for discard or perk usage" : "Selected for discard";
});
</script>

<template>
  <div class="flex flex-col items-center justify-center pb-12">
    <!-- <div class="mb-8">
      <img :src="require('@/assets/chapter-1/soth-clear-logo.png')" class="hidden md:block h-20" />
    </div> -->

    <template v-if="game">
      <div class="text-center font-display text-white text-xl mb-6" v-if="game.completed_at">
        Hand complete!
      </div>
      <div class="text-center font-display text-white text-xl mb-6" v-else>
        Pick the card(s)<br />
        you want to swap, then <span class="text-ch1gold italic">draw!</span>
      </div>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="gameboard container mb-12">
          <div class="flex flex-col items-center mb-8">
            <div class="flex flex-wrap justify-center">
              <template v-for="card in game.hand" :key="card">
                <Card
                  :card="card"
                  @select="selectCard(card)"
                  :selected="isSelected(card)"
                  :selected-text="selectedText"
                  :initial="!!game.initial_hand?.find((c) => c == card)"
                />
              </template>
            </div>
          </div>

          <div
            v-if="powerupMessage"
            class="p-4 text-center bg-ch1cream rounded-lg mb-4 w-full max-w-md mx-auto font-serif"
          >
            <div class="italic text-gray-400 font-bold">Last game message:</div>
            <div>{{ powerupMessage }}</div>
          </div>

          <div class="text-ch1gold font-display text-3xl mb-12 text-center">
            <div>
              {{ game.result_description }}
            </div>
            <div class="text-lg" v-if="game.completed_at">
              <span class="text-white">You won</span> {{ game.winnings_final }}
              <span class="text-white"
                >chip{{ game.winnings_final == 1 ? "" : "s"
                }}{{ game.winnings_final > 0 ? "!" : "." }}</span
              >
            </div>
          </div>

          <div class="flex justify-center">
            <template v-if="game && !game.completed_at">
              <DrawButton @select="tradeCards" />
            </template>
            <template v-else-if="day.max_games > day.games.length">
              <button class="btn btn-ch1lightblue" @click="startGame">Start New Hand</button>
            </template>
            <template v-else>
              <div>
                <div class="text-white">No more hands - come back tomorrow!</div>
                <div class="mt-4 font-display text-white text-xl text-center">
                  Game resets in {{ countdownDisplay }}
                </div>
                <div class="text-center mt-8">
                  <router-link :to="{ name: 'PokerStandard' }" class="btn btn-blue"
                    >Play Standard Draw Poker</router-link
                  >
                </div>
              </div>
            </template>
          </div>
        </div>

        <div class="mb-8 container">
          <div class="flex flex-wrap justify-center">
            <template v-for="(pow, p) in day.powerup_data" :key="p">
              <template v-if="pow.powerup_uses > 0">
                <TokenCard
                  :token="pow"
                  @select="usePowerup"
                  :btnLabel="`Use Perk`"
                  usesLabel="Remaining"
                />
              </template>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="container">
        <div class="py-24 flex justify-center">
          <button class="btn btn-ch1lightblue text-white" @click="startGame">Start Game</button>
        </div>
      </div>
    </template>
  </div>

  <!-- <Fixed v-if="powerupMessage">
    <Overlay @click="clearPowerupMessage">
      <Modal> {{ powerupMessage }} </Modal>
    </Overlay>
  </Fixed> -->

  <Fixed v-if="loading">
    <Overlay>
      <Modal> {{ loading }}... </Modal>
    </Overlay>
  </Fixed>
</template>
