<script setup>
import Fixed from "../Fixed.vue";
import Overlay from "../Overlay.vue";
import Modal from "../Poker/Modal.vue";
import store from "@/store";
import { computed, onMounted, ref } from "vue";
import { DateTime } from "luxon";
import ToggleButtons from "./ToggleButtons.vue";
import CardMemory from "./CardMemory.vue";
import { sampleSize, sample, shuffle } from "lodash-es";

const leaderboardData = ref(null);
const loading = ref(false);
const error = ref(null);

const hideMemory = () => {
  store.dispatch("poker/setShowMemory", false);
};
const nothing = () => {
  //  shouldn't hide
};

const mode = ref("Play"); // today, week, overall
const modes = ["Play", "Rules"];

const gridSize = [4, 5];

const cardsNeeded = (gridSize[0] * gridSize[1]) / 2;

const selectedNumbers = sampleSize(
  ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13"],
  cardsNeeded
);

const selectedCards = selectedNumbers.map((num) => {
  const suit = sample(["H", "D", "C", "S"]);
  return `${suit}${num}`;
});

const doubledCards = [...selectedCards, ...selectedCards];

const shuffledCards = shuffle(doubledCards);

const gameData = ref(
  shuffledCards.map((card) => {
    return {
      id: card,
      selected: false,
    };
  })
);

const currentSelection = ref([]);

const locked = ref(false);

const totalMoves = ref(0);
// await new Promise((resolve) => setTimeout(resolve, 700));
const clickCard = async (index) => {
  if (locked.value) {
    return;
  }

  if (gameData.value[index].selected) {
    return;
  }

  locked.value = true;

  gameData.value[index].selected = true;

  currentSelection.value = [...currentSelection.value, index];

  if (currentSelection.value.length > 1) {
    totalMoves.value += 1;
    if (
      gameData.value[currentSelection.value[0]].id !== gameData.value[currentSelection.value[1]].id
    ) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      currentSelection.value.forEach((ind) => {
        gameData.value[ind].selected = false;
      });
    }

    currentSelection.value = [];
  }

  locked.value = false;
};

const won = computed(() => {
  return gameData.value.length === gameData.value.filter((card) => card.selected).length;
});

const timer = ref(null);
</script>

<template>
  <Fixed>
    <Overlay @click="hideMemory">
      <Modal @click.stop="nothing">
        <div class="leaderboard-modal max-w-full">
          <div
            class="w-full flex flex-col lg:flex-row justify-between, items-start lg:items-center"
          >
            <div class="flex mb-4 lg:mb-0 mt-4">
              <div class="font-ch1script text-6xl text-ch1blue">Memory</div>
            </div>
            <div class="flex flex-grow md:justify-end flex-wrap md:flex-nowrap">
              <!-- <ToggleButtons v-model="mode" :modes="modes" /> -->
            </div>
          </div>
          <div class="my-6 text-left overflow-auto w-full">
            <div class="w-full" v-if="mode === 'Play'">
              <div class="font-display mb-6 text-gray-500 italic">
                Pass the time while you wait for more poker with a game of memory!
              </div>
              <div class="flex justify-center">
                <div class="text-center">
                  <div class="text-xl font-display mb-4">
                    Total Moves: {{ totalMoves }}{{ won ? ", YOU WON!" : null }}
                  </div>
                  <div class="grid gap-0 grid-cols-4 md:grid-cols-5">
                    <CardMemory
                      v-for="(card, c) in gameData"
                      :key="c"
                      :card="card.id"
                      :selected="card.selected"
                      @click="clickCard(c)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full" v-if="mode === 'Rules'">
              <div class="text-xl font-display mb-4">Rules</div>
              <div>
                <CardMemory :card="`H01`" />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Overlay>
  </Fixed>
</template>

<style lang="postcss">
@media (min-width: 1100px) {
  .leaderboard-modal {
    width: 1000px;
  }
}
</style>
