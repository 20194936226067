<script setup>
import store from "@/store";
import { computed, ref, defineProps } from "vue";
import router from "../../router";
import AppDropdown from "../Dropdown/AppDropdown.vue";
import AppDropdownContent from "../Dropdown/AppDropdownContent.vue";
import AppDropdownItem from "../Dropdown/AppDropdownItem.vue";

const props = defineProps({
  freeplay: Boolean,
});

const loading = ref(false);

const day = computed(() => {
  return store.state.poker.day;
});

const tempGameScore = computed(() => {
  return store.state.poker.tempGameScore;
});

const tempGameCounts = computed(() => {
  return store.state.poker.tempGameCounts;
});

const login = async () => {
  loading.value = true;
  await store.dispatch("auth/login");
  loading.value = false;
};

const logout = async () => {
  loading.value = true;
  await store.dispatch("auth/logout");
  loading.value = false;
};

const isLoggedIn = computed(() => {
  return store.getters["auth/isLoggedIn"];
});
const address = computed(() => {
  if (!store.state.auth.user?.username) {
    return null;
  }

  if (store.state.auth.user.username.length > 20) {
    return `${store.state.auth.user.username?.substring(
      0,
      7
    )}...${store.state.auth.user.username?.substring(store.state.auth.user.username?.length - 7)}`;
  }

  return store.state.auth.user.username;
});

const gotoprofile = () => {
  router.push({ name: "Profile" });
};

const gotogallery = () => {
  router.push({ name: "Gallery" });
};

const showLeaderboard = () => {
  store.dispatch("poker/setShowLeaderboard", true);
};

const showRules = () => {
  store.dispatch("poker/setShowRules", true);
};

const showStats = () => {
  store.dispatch("poker/setShowStats", true);
};

const countdownDisplay = computed(() => {
  return store.state.poker?.gameResetDisplay;
});
</script>

<template>
  <div
    class="bg-ch1cream box-shadow-std hidden md:block bg-cover py-4 z-nav rounded-lg"
    :style="{
      // 'background-image': `url('${require('@/assets/v2/paper-cream-sm-min.jpg')}')`
    }"
  >
    <div class="container flex items-center justify-between py-0">
      <div class="left flex-shrink-0 -mb-2">
        <router-link :to="{ name: 'Poker' }" :exact="true">
          <!-- <img :src="require('@/assets/chapter-1/texas-hodl-em-logo.png')" style="height: 100%" /> -->
          <div class="font-ch1script text-4xl text-ch1blue" v-if="!freeplay">Powerup Poker</div>
          <div class="font-ch1script text-4xl text-ch1blue" v-else>Standard Draw Poker</div>
        </router-link>
      </div>

      <div class="center font-display hidden md:block">
        <div v-if="props.freeplay" class="flex justify-between">
          <div class="flex items-center mr-6">
            <div class="italic text-black">Today's Winnings:</div>
            <img :src="require('@/assets/chapter-1/icon-winnings.png')" class="w-4 ml-2 mr-2" />
            <div class="text-black">{{ tempGameScore }}</div>
          </div>
          <div class="flex items-center">
            <div class="italic text-black">Hands Played:</div>
            <img :src="require('@/assets/chapter-1/icon-games-played.png')" class="w-4 ml-2 mr-2" />
            <div class="text-black">
              {{ tempGameCounts?.played ?? 0 }} <span class="text-black">of</span>
              {{ tempGameCounts?.total ?? `~` }}
            </div>
          </div>
        </div>
        <div v-else-if="day" class="flex justify-between">
          <div class="flex items-center mr-6">
            <div class="italic text-black">Today's Winnings:</div>
            <img :src="require('@/assets/chapter-1/icon-winnings.png')" class="w-4 ml-2 mr-2" />
            <div class="text-ch1blue">{{ day.winnings }}</div>
          </div>
          <div class="flex items-center">
            <div class="italic text-black">Hands Played:</div>
            <img :src="require('@/assets/chapter-1/icon-games-played.png')" class="w-4 ml-2 mr-2" />
            <div class="text-ch1blue">
              {{ day.games?.length }} <span class="text-black">of</span> {{ day.max_games ?? `~` }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <template
    v-if="!props.freeplay"
    :style="{
      // 'background-image': `url('${require('@/assets/v2/paper-cream-sm-min.jpg')}')`
    }"
  >
    <div
      v-if="day"
      class="bg-ch1cream block md:hidden px-2 py-2 md:py-4 text-xs text-right z-nav box-shadow-std bg-cover rounded-lg"
    >
      <div class="flex justify-between">
        <div class="flex items-center">
          <div class="italic text-black">Today's Winnings:</div>
          <img :src="require('@/assets/chapter-1/icon-winnings.png')" class="w-4 ml-1 mr-1" />
          <div class="text-black">{{ day.winnings }}</div>
        </div>
        <div class="flex items-center">
          <div class="italic text-black">Hands Played:</div>
          <img :src="require('@/assets/chapter-1/icon-games-played.png')" class="w-4 ml-1 mr-1" />
          <div class="text-black">
            {{ day.games?.length }} <span class="text-black">of</span> {{ day.max_games ?? `~` }}
          </div>
        </div>
      </div>
    </div>
  </template>
  <div
    class="bg-ch1cream block md:hidden box-shadow-std px-2 py-2 z-nav text-xs text-right bg-cover rounded-lg"
    :style="{
      // 'background-image': `url('${require('@/assets/v2/paper-cream-sm-min.jpg')}')`
    }"
    v-else
  >
    <div class="flex justify-between">
      <div class="flex items-center">
        <div class="italic text-black">Today's Winnings:</div>
        <img :src="require('@/assets/chapter-1/icon-winnings.png')" class="w-4 ml-1 mr-1" />
        <div class="text-ch1blue">{{ tempGameScore ?? 0 }}</div>
      </div>
      <div class="flex items-center">
        <div class="italic text-black">Hands Played:</div>
        <img :src="require('@/assets/chapter-1/icon-games-played.png')" class="w-4 ml-1 mr-1" />
        <div class="text-ch1blue">
          {{ tempGameCounts?.played ?? 0 }} <span class="text-black">of</span>
          {{ tempGameCounts?.total ?? `~` }}
        </div>
      </div>
    </div>
  </div>
</template>
