<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { DateTime } from "luxon";
import CardSmall from "./CardSmall.vue";
import TokenCardSmall from "./TokenCardSmall.vue";

const props = defineProps({
  rank: Number,
  record: Object,
  dates: Array,
  opened: Boolean,
});

// {
//   username:
//   winnings_total
//   poker_days: [ { date:  } ]
// }

const emit = defineEmits(["selected"]);

const days = computed(() => {
  if (!props.dates?.length) {
    return props.record?.poker_days ?? [];
  }

  return props.dates?.map((date) => {
    return props.record?.poker_days?.find((day) => {
      return day.date === date;
    });
  });
});

const formatDate = (date) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
};

const abbrevAddress = (address) => {
  return `${address?.substring(0, 5)}...${address?.substring(address?.length - 4)}`;
};
</script>

<template>
  <div class="flex flex-col flex-grow p-4 bg-white border border-ch1blue-light rounded mb-4">
    <div class="flex flex-grow items-center cursor-pointer" @click="emit('selected', props.rank)">
      <div
        class="flex flex-col justify-center items-center rounded-full p-2 text-xs bg-ch1blue text-white w-10 h-10 mr-4"
      >
        #{{ props.rank }}
      </div>
      <div class="text-lg font-medium mr-6 truncate">{{ props.record?.username }}</div>
      <div class="italic mr-6">{{ props.record.winnings_total }} chips</div>
      <!-- <div class="text-xs uppercase" v-if="props.record.games_count">
        {{ props.record.games_count }} hands
      </div> -->
    </div>
    <div v-if="opened" class="border-t border-ch1blue mt-4 pt-4">
      <template v-for="(day, d) in days" :key="d">
        <div v-for="(game, g) in day.games" :key="g" class="mb-4 pb-2 border-b">
          <div class="font-display mb-2">
            Result: {{ game.result_description ?? "Nothing" }} ({{ game.winnings_final }} chips)
          </div>
          <div class="flex mb-2">
            <div class="flex flex-wrap justify-start">
              <CardSmall
                v-for="(card, c) in game.hand"
                :card="card"
                :key="c"
                :initial="!!game.initial_hand?.find((c) => c == card)"
              />
            </div>
          </div>
          <template v-if="game?.discards?.length">
            <div class="font-display mb-2 text-sm text-gray-400">Discards</div>
            <div class="flex mb-2">
              <div class="flex flex-wrap justify-start">
                <CardSmall
                  v-for="(card, c) in game.discards"
                  :card="card"
                  :key="c"
                  :initial="!!game.initial_hand?.find((c) => c == card)"
                />
              </div>
            </div>
          </template>
          <template v-if="game?.powerup_data?.length">
            <div class="font-display mb-2 text-sm text-gray-400">Powerups</div>
            <div class="flex flex-wrap">
              <TokenCardSmall v-for="(powerup, p) in game.powerup_data" :key="p" :token="powerup" />
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>
