<script setup>
import { defineProps, defineEmits } from "vue";
import cardFiles from "@/utils/cards";

const cardFile = (id) => {
  return cardFiles[id];
};

const props = defineProps({
  card: String,
  selected: Boolean,
});

const emit = defineEmits(["select"]);
</script>

<template>
  <div class="overflow-hidden p-1 md:p-3">
    <div class="memory-card-scene">
      <div class="memory-card" :class="{ 'memory-card-selected': props.selected }">
        <div class="memory-card-face memory-card-front">
          <img :src="cardFile(props.card)" class="w-full" />
        </div>
        <div class="memory-card-face memory-card-back">
          <img :src="cardFile('BACK')" class="w-full" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="pcss">
.memory-card-scene {
  width: 6em;
  height: 9em;
  perspective: 30em;
  filter: drop-shadow(-3px 3px 0px rgba(0, 0, 0, 0.25));
}

.memory-card {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
  @apply cursor-pointer;
  /* filter: drop-shadow(-3px 3px 0px rgba(0, 0, 0, 0.25)); */
}

.memory-card-face {
  width: 100%;
  height: 100%;

  color: #fff;
  line-height: 15em;
  text-align: center;
  position: absolute;

  backface-visibility: hidden;
}

/* .memory-card-front {
    background-color: #0379ff;
} */

.memory-card-back {
  /* background-color: #333; */
  transform: rotateY(180deg);
}

.memory-card-selected {
  transform: rotateY(0deg);
}

@media (max-width: 450px) {
  .memory-card-scene {
    width: 4em;
    height: 6em;
  }
}
</style>
