<script setup>
import Fixed from "../Fixed.vue";
import Overlay from "../Overlay.vue";
import Modal from "../Poker/Modal.vue";
import store from "@/store";
import { onMounted, ref } from "vue";
import { DateTime } from "luxon";
import ToggleButtons from "./ToggleButtons.vue";

const leaderboardData = ref(null);
const loading = ref(false);
const error = ref(null);

const hideRules = () => {
  store.dispatch("poker/setShowRules", false);
};
const nothing = () => {
  //  shouldn't hide
};

const mode = ref("Gameplay"); // today, week, overall
const modes = [
  "Gameplay",
  // "Powerups",
  "Earning",
  // "More Info"
];

const formatDate = (date) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
};

const winningsTable = [
  // 0: { name: "None", multiplier: 0 },
  // 1: { name: "Pair", multiplier: 0.5 },
  // 2: { name: "Jacks or Better", multiplier: 1 },
  // 3: { name: "Two Pair", multiplier: 2 },
  // 4: { name: "Three of a Kind", multiplier: 4 },
  // 5: { name: "Straight", multiplier: 6 },
  // 6: { name: "Flush", multiplier: 8 },
  // 7: { name: "Full House", multiplier: 10 },
  // 8: { name: "Four of a Kind", multiplier: 30 },
  // 9: { name: "Straight Flush", multiplier: 50 },
  // 10: { name: "Royal Flush", multiplier: 100 },

  //     $winningsTable = [
  //     0 => 0,
  //     1 => 2,
  //     2 => 6,
  //     3 => 12,
  //     4 => 20,
  //     5 => 30,
  //     6 => 42,
  //     7 => 56,
  //     8 => 72,
  //     9 => 90,
  //     10 => 110,
  // ];
  {
    name: "Pair",
    multiplier: 2,
  },
  {
    name: "Pair of Jacks or Better",
    multiplier: 6,
  },
  {
    name: "Two Pair",
    multiplier: 12,
  },
  {
    name: "Three of a Kind",
    multiplier: 20,
  },
  {
    name: "Straight",
    multiplier: 30,
  },
  {
    name: "Flush",
    multiplier: 42,
  },
  {
    name: "Full House",
    multiplier: 56,
  },
  {
    name: "Four of a Kind",
    multiplier: 72,
  },
  {
    name: "Straight Flush",
    multiplier: 90,
  },
  {
    name: "Royal Flush",
    multiplier: 110,
  },
];

const tokenList = [
  {
    contract: "Society of the Hourglass",
    address: "0x116fDa1BF1f9C38A98Afe2A8FD67Ba5b9714dfa9",
    token: "Smiley",
    perk: "One More Please",
    uses: 4,
    description: "Pull an extra card from the top of the deck into your hand.",
  },
  {
    contract: "Society of the Hourglass",
    address: "0x116fDa1BF1f9C38A98Afe2A8FD67Ba5b9714dfa9",
    token: "Skinny",
    perk: "Seeing Double",
    uses: 2,
    description: "Select a card and pull one with the same value.",
  },
  {
    contract: "Society of the Hourglass",
    address: "0x116fDa1BF1f9C38A98Afe2A8FD67Ba5b9714dfa9",
    token: "Oldy",
    perk: "Just A Peek",
    uses: 7,
    description: "Reveal what the next 6 cards in the deck will be.",
  },
  {
    contract: "Society of the Hourglass",
    address: "0x116fDa1BF1f9C38A98Afe2A8FD67Ba5b9714dfa9",
    token: "Stocky",
    perk: "Seeing Double",
    uses: 2,
    description: "Select a card and pull one with the same value.",
  },
  {
    contract: "Society of the Hourglass",
    address: "0x116fDa1BF1f9C38A98Afe2A8FD67Ba5b9714dfa9",
    token: "Shady",
    perk: "Ace Up the Sleeve",
    uses: 3,
    description: "Pull the an ace from the deck into your hand.",
  },
  {
    contract: "Society of the Hourglass",
    address: "0x116fDa1BF1f9C38A98Afe2A8FD67Ba5b9714dfa9",
    token: "Junior",
    perk: "What Are These For?",
    uses: 4,
    description: "Swap any 3 cards without it counting as your draw.",
  },
  {
    contract: "Society of the Hourglass",
    address: "0x116fDa1BF1f9C38A98Afe2A8FD67Ba5b9714dfa9",
    token: "Competitor",
    perk: "I’m Done Here",
    uses: 4,
    description: "Reset the round and receive 2 extra cards.",
  },
  {
    contract: "Society of the Hourglass",
    address: "0x116fDa1BF1f9C38A98Afe2A8FD67Ba5b9714dfa9",
    token: "Steward",
    perk: "I’m Done Here",
    uses: 4,
    description: "Reset the round and receive 2 extra cards.",
  },
  {
    contract: "Society of the Hourglass",
    address: "0x116fDa1BF1f9C38A98Afe2A8FD67Ba5b9714dfa9",
    token: "Nigel",
    perk: "I’m Done Here",
    uses: 4,
    description: "Reset the round and receive 2 extra cards.",
  },
  {
    contract: "Cabinet of Curiosities",
    address: "0xFc35EFd86e63E0Ef336f36c8ccF72fe942a58556",
    token: "Forget-Me-Dust",
    perk: "Forget I Was Here",
    uses: 6,
    description: "Reset the round.",
  },
  {
    contract: "Cabinet of Curiosities",
    address: "0xFc35EFd86e63E0Ef336f36c8ccF72fe942a58556",
    token: "Pocket Spectroscope",
    perk: "Card Scope",
    uses: 7,
    description: "Reveal what the next 6 cards in the deck will be.",
  },
  {
    contract: "Cabinet of Curiosities",
    address: "0xFc35EFd86e63E0Ef336f36c8ccF72fe942a58556",
    token: "Book of Epochs",
    perk: "So Let It Be Written",
    uses: 1,
    description: "Pull a Joker into your hand (limit of one per hand).",
  },
  {
    contract: "Cabinet of Curiosities",
    address: "0xFc35EFd86e63E0Ef336f36c8ccF72fe942a58556",
    token: "Book of Epochs (Closed)",
    perk: "So Let It Be Written",
    uses: 1,
    description: "Pull a Joker into your hand (limit of one per hand).",
  },
  {
    contract: "Cabinet of Curiosities",
    address: "0xFc35EFd86e63E0Ef336f36c8ccF72fe942a58556",
    token: "Auto-translator Bot",
    perk: "Bot Prerogative",
    uses: 2,
    description: "Swap any 3 cards without it counting as your draw.",
  },
  {
    contract: "Cabinet of Curiosities",
    address: "0xFc35EFd86e63E0Ef336f36c8ccF72fe942a58556",
    token: "Auto-translator Bot (Activated)",
    perk: "Bot Prerogative",
    uses: 3,
    description: "Swap any 3 cards without it counting as your draw.",
  },
  {
    contract: "ZenAcademy",
    address: "0xf64e6fb725f04042b5197e2529b84be4a925902c",
    token: "",
    perk: "Tutelage of a Master",
    uses: 1,
    description: "For every 3 in your hand, pull a king.",
  },
  {
    contract: "Cryptomories",
    address: "0x1a2F71468F656E97c2F86541E57189F59951efe7",
    token: "",
    perk: "Adapt Upward",
    uses: 1,
    description: "Swap a card for one of the next value up (ex: 10 for a jack).",
  },
  {
    contract: "Doodles",
    address: "0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e",
    token: "",
    perk: "Wear A Different Suit",
    uses: 1,
    description: "Swap 2 cards for 2 with a matching suit (different from your picks).",
  },
  {
    contract: "Cranky Critters",
    address: "0xd3a517536Da3491db848D260C1f3D81883B1aA7A",
    token: "",
    perk: "F#@$ this S@#$",
    uses: 1,
    description: "Reset the round.",
  },
  {
    contract: "Little Lemon Friends",
    address: "0x0B22fE0a2995C5389AC093400e52471DCa8BB48a",
    token: "",
    perk: "Add ‘Em Up",
    uses: 1,
    description: "Pick 2 cards and pull in a card of their combined value.",
  },
  {
    contract: "Top Dog Beach Club",
    address: "0x6F0365ca2c1Dd63473F898A60f878A07e0f68A26",
    token: "",
    perk: "Dogs on the Loose",
    uses: 1,
    description: "Discard 3 random cards, get 4 in return.",
  },
  {
    contract: "World of Women",
    address: "0xe785E82358879F061BC3dcAC6f0444462D4b5330",
    token: "",
    perk: "Queens to You",
    uses: 1,
    description: "Pull the next queen from the deck into your hand.",
  },
  {
    contract: "Deadfellaz",
    address: "0x2acAb3DEa77832C09420663b0E1cB386031bA17B",
    token: "",
    perk: "Bury the Card",
    uses: 1,
    description: "Swap a card for one of the next value down (ex: 7 for a 6).",
  },
  {
    contract: "Mystic Wizards",
    address: "0xbE6A2F5960B27c7fde12E073d61b962D9C6c3cB7",
    token: "",
    perk: "Kings Are Wizards",
    uses: 1,
    description: "If you have a King in your hand, you can get a Joker.",
  },
  {
    contract: "Bitlectro Dreamloops",
    address: "0xf1B33aC32dbC6617f7267a349be6ebb004FeCcff",
    token: "",
    perk: "Time Is A Construct",
    uses: 1,
    description: "Gain an extra 3 hands to play today.",
  },
];

const bonuses = {
  1: 50,
  2: 100,
  3: 150,
  4: 210,
  5: 240,
  6: 260,
  7: 270,
  8: 280,
  9: 290,
  10: 300,
  11: 310,
  12: 320,
  13: 330,
  14: 340,
  15: 350,
};

const bonusMultiplier = (count) => {
  if (count > 15) {
    return bonuses[15] * count;
  } else if (count === 0) {
    return 0;
  } else {
    return bonuses[count] * count;
  }
};
</script>

<template>
  <Fixed>
    <Overlay @click="hideRules">
      <Modal @click.stop="nothing">
        <div class="leaderboard-modal max-w-full">
          <div
            class="w-full flex flex-col lg:flex-row justify-between, items-start lg:items-center"
          >
            <div class="flex mb-4 lg:mb-0 mt-4">
              <div class="font-ch1script text-6xl text-ch1blue">Rules</div>
            </div>
            <div class="flex flex-grow md:justify-end flex-wrap md:flex-nowrap">
              <ToggleButtons v-model="mode" :modes="modes" />
            </div>
          </div>
          <div class="my-6 text-left overflow-auto w-full">
            <div class="w-full" v-if="mode === 'Gameplay'">
              <div class="text-xl font-display mb-4">Gameplay</div>
              <div>
                <p>
                  Welcome to Society of the Hourglass Powerup Poker! This game is a
                  specially-concocted version of draw poker. If you are unfamiliar with draw poker,
                  you are in the right place!
                </p>
              </div>
              <div class="text-xl font-display mb-4 mt-6">Winning Hands</div>
              <div>
                <p>
                  Games of poker come in many variations, but the common theme is that the goal is
                  to end up with the best possible combination of cards at the end of a round. Below
                  you can see the winning combinations, in ascending order of rank:
                </p>
                <ul>
                  <li>
                    <strong>Pair:</strong> Any two cards with matching values (e.g. the 5 of Clubs
                    and 5 of Hearts).
                  </li>
                  <li>
                    <strong>Pair of Jacks or Better:</strong> This is a special version of a pair
                    where the matching cards are at least as high-valued as a Jack (e.g. the King of
                    Diamonds and King of Spades).
                  </li>
                  <li>
                    <strong>Two Pair:</strong> Two distinct pairs of values (e.g. the 7 of Clubs and
                    7 of Diamonds and Queen of Diamonds and Queen of Hearts).
                  </li>
                  <li>
                    <strong>Three of a Kind:</strong> Three cards with matching values (e.g. the 8
                    of Spades, the 8 of Hearts, and the 8 of Diamonds).
                  </li>
                  <li>
                    <strong>Straight:</strong> Five cards with values that are in sequential order,
                    regardless of suit (e.g. the following cards: 2 of Hearts, 3 of Hearts, 4 of
                    Diamonds, 5 of Spades, 6 of Clubs).
                  </li>
                  <li>
                    <strong>Flush:</strong> Five cards with matching suits, regardless of their
                    values (e.g. five cards that are all spades: 5 of Spades, 8 of Spades, Jack of
                    Spades, Queen of Spades, Ace of Spades).
                  </li>
                  <li>
                    <strong>Full House:</strong> A pair and a 3 of a kind (e.g. the 2 of Spades and
                    2 of Hearts and the 6 of Clubs, 6 of Hearts, and 6 of Diamonds).
                  </li>
                  <li>
                    <strong>Four of a Kind:</strong> Four cards with matching values (e.g. 6 of
                    Hearts, 6 of Spades, 6 of Diamonds, 6 of Clubs).
                  </li>
                  <li>
                    <strong>Straight Flush:</strong> A Straight where all the cards have a matching
                    suit (e.g. 4 of Clubs, 5 of Clubs, 6 of Clubs, 7 of Clubs, and 8 of Clubs).
                  </li>
                  <li>
                    <strong>Royal Flush:</strong> A special version of a Straight Flush where the
                    cards are the highest possible values (e.g. 10 of Hearts, Jack of Hearts, Queen
                    of Hearts, King of Hearts, and Ace of Hearts).
                  </li>
                </ul>
                <p>
                  You can find a list of how these hands reward you on the
                  <span class="underline text-ch1blue cursor-pointer" @click="mode = 'Earning'"
                    >"Earning" tab</span
                  >.
                </p>
              </div>
              <div class="text-xl font-display mb-4 mt-6">Order of Play</div>
              <div>
                <p>The order of play for our game is fairly straightforward:</p>
                <ol>
                  <li>
                    <strong>START / DEAL:</strong> You start the game and are dealt 5 cards from a
                    shuffled deck.
                  </li>
                  <!-- <li>
                    <strong>(OPTIONAL) USE POWERUPS:</strong> At this point you can use
                    <span class="underline text-ch1blue cursor-pointer" @click="mode = 'Powerups'">
                      any powerups
                    </span>
                    that you have available to help you win this particular round.
                  </li> -->
                  <li>
                    <strong>DRAW:</strong> You evaluate what you are given, choose which cards you
                    would like to discard in exchange for the same number of new cards from the top
                    of the deck, then click the "Draw" button.
                  </li>
                  <li>
                    <strong>WIN:</strong> After the Draw you receive your cards and
                    <span class="underline text-ch1blue cursor-pointer" @click="mode = 'Earning'"
                      >are rewarded</span
                    >
                    based on what the best possible hand is.
                  </li>
                </ol>
                <p>
                  At the DRAW, you do not have to select any cards to trade if you already have a
                  winning hand that you are happy with, but you will need to click the "Draw" button
                  to complete the game and get your winnings.
                </p>
              </div>
              <div class="text-xl font-display mb-4 mt-6">Other Relevant Rules</div>
              <div>
                <ul>
                  <li>
                    You have a limited number of hands for each mode - in the top right you can see
                    how many hands you have played and how many you are allowed total.
                  </li>
                  <li>Days reset at midnight UTC.</li>
                  <li>
                    You must complete a game (by clicking the "Draw" button) in order to have it
                    count toward your winnings total.
                  </li>
                  <li>All powerups can only be used before the draw.</li>
                  <li>
                    Some powerups allow you to get a Joker, which will stand in as any other card by
                    value and suit.
                  </li>
                  <li>You can never get more than one Joker in a given hand.</li>
                  <li>
                    At any point we may adjust rules, rewards, powerups, or any other aspect of the
                    game - we are optimizing for fun and believe the best way to do that will be to
                    see what works and always make changes as needed.
                  </li>
                </ul>
              </div>
            </div>
            <div class="w-full" v-if="mode === 'Powerups'">
              <div class="text-xl font-display mb-4">List of Powerups</div>
              <!-- <div>"https://etherscan.io/address/0xFc35EFd86e63E0Ef336f36c8ccF72fe942a58556"</div> -->
              <div>
                <p>
                  <em>The following is a list of tokens that grant you powerups in our game.</em>
                </p>
                <div class="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  <div
                    class="mb-4 pt-4 border-t border-gray flex flex-col justify-start bg-white p-4 rounded border border-ch1blue"
                    v-for="(token, t) in tokenList"
                    :key="t"
                  >
                    <div class="text-sm font-bold">
                      <a
                        class="underline text-ch1blue"
                        :href="`https://etherscan.io/address/${token.address}`"
                        target="_blank"
                      >
                        {{ token.contract }}
                      </a>
                    </div>
                    <div class="font-bold text-lg">
                      {{ token.token !== "" ? token.token : "[Any Token]" }}
                    </div>
                    <div class="mb-2">
                      <span class="font-medium italic">{{ token.perk }}:</span>
                      {{ token.description }}
                    </div>
                    <div class="flex">
                      <div
                        class="italic font-medium text-white px-4 py-1 text-xs rounded-full bg-ch1blue-light"
                      >
                        Daily Uses: {{ token.uses }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <table>
                  <thead>
                    <tr class="border-b border-gray">
                      <th class="py-2 pr-4">Contract</th>
                      <th class="py-2 pr-4">Token Type</th>
                      <th class="py-2 pr-4">Daily Uses</th>
                      <th class="py-2 pr-4">Perk Name</th>
                      <th class="py-2 pr-4">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(token, t) in tokenList" :key="t">
                      <td class="py-2 pr-4">
                        <a class="underline text-ch1blue" :href="`https://etherscan.io/address/${token.address}`" target="_blank">{{ token.contract }}</a>
                      </td>
                      <td class="py-2 pr-4">{{ token.token !== "" ? token.token : "Any" }}</td>
                      <td class="py-2 pr-4">{{ token.uses }}</td>
                      <td class="py-2 pr-4">{{ token.perk }}</td>
                      <td class="py-2 pr-4">{{ token.description }}</td>
                    </tr>
                  </tbody>
                </table> -->
              </div>
            </div>
            <div class="w-full" v-if="mode === 'Earning'">
              <div class="mt-4 pt-4 border-t border-gray-500">
                <div class="text-xl font-display mb-4">Poker Rewards Table</div>
                <div>
                  <p><em>The following table shows the possible rewards.</em></p>
                  <table>
                    <thead>
                      <tr class="border-b border-gray">
                        <th class="py-2 pr-4">Hand</th>
                        <th class="py-2 pr-4">Reward</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(hand, h) in winningsTable" :key="h">
                        <td class="py-2 pr-4">{{ hand.name }}</td>
                        <td class="py-2 pr-4">{{ hand.multiplier * 2 }} chips</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="w-full" v-if="mode === 'More Info'">More info</div>
          </div>
        </div>
      </Modal>
    </Overlay>
  </Fixed>
</template>

<style lang="postcss">
@media (min-width: 1100px) {
  .leaderboard-modal {
    width: 1000px;
  }
}
</style>
